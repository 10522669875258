.anticon svg {
  color: linear-gradient(135deg, #fbda61 2.88%, #ffc371 100%);
}
.ant-rate-star-half .ant-rate-star-first {
  color: linear-gradient(180deg, #f4df73 0%, #ffb803 100%);
}

.antd-menuu {
  background: #343434 !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: linear-gradient(
    226.42deg,
    #1b4963 8.93%,
    #09152f 110.98%
  ) !important;
}

.ant-progress-circle {
  width: 40px !important;
  height: 40px !important;
}
.ant-upload-text {
  font-size: 10px !important;
}

.ant-progress-circle-gradient {
  width: 50px !important;
  height: 50px !important;
}
.ant-progress-bg {
  background: linear-gradient(
    148.54deg,
    #ffe925 20.91%,
    #ffaa39 105.47%
  ) !important;
}

.ant-progress-text {
  display: none !important;
}
.site {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.anticon-menu-unfold svg {
  height: 30px;
  margin-left: 10px;
  font-size: 20px;
  margin-top: 15px;
}
.anticon-menu-fold svg {
  height: 30px;
  margin-left: 10px;
  font-size: 20px;
  margin-top: 15px;
}
.ant-upload {
  padding: 0px !important;
}

.ant-layout-header {
  background-color: #ffffff;
}

@media only screen and (max-width: 787px) {
  .ant-layout-header {
    background: linear-gradient(
      282.12deg,
      #1b4963 17.45%,
      #09152f 74.07%
    ) !important;
    height: 50px !important;
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.otherthings__container {
  width: 500px;
  display: flex;
  justify-content: space-around;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: 0 !important;
}

.ant-list-item {
  padding: 0 !important;
  /* width: 320px !important; */
}

.ant-menu-item-selected {
  background-color: rgba(63, 86, 116, 1) !important;
}

.ant-menu-item-selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  height: 90%;
  width: 26px;
  background-color: #2d3748;
  border-radius: 5px;
  transform: translateY(5%);
}

.menu-item span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.setting-menu-item span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.animate {
  animation: anim 5s all forwards;
}

@keyframes anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.filter-container {
  min-width: 240px;
  width: 240px;
}
